<template>
<div class="referals" style="width: 100%;" :style="`padding-bottom:${isMobile?'50px':'0'};`">

  <v-alert type="success" class="copiedTelegram" v-if="copied">
    Unique url copied to your clipboard!
  </v-alert>

  <h1 class="heading" v-if="!isMobile">Referrals</h1>
  <div style="display: flex;flex-direction: column;border-bottom: 1px solid #ccc;padding-bottom:50px;">

  <div style="display:flex;" :style="`flex-direction:${isMobile?'column':'row'};`">
  <div style="flex:4;display:flex;flex-direction: column;">
  <div style="margin-bottom:30px;font-weight: bold;">
    Refer a friend and earn 20$ account credit
  </div>
        <div style="margin-bottom:15px;font-size:18px;">
          1. Simply share your unique link below:
        </div>
        <div style="margin-bottom:15px;font-size:18px;">
          2. Anyone who signs up from your link will be automatically tracked.
        </div>
        <div style="margin-bottom:15px;font-size:18px;">
          3. Shortly after they`ve completed their first paid, we will issue  the credit to your account.
        </div>
        <div style="margin-bottom:15px;font-size:18px;">
          4. Your account credit then be used towards your future subsription payments.
        </div>
      </div>
    <div :style="`width:${isMobile?'100%':'300px'};`" style="border-radius:5px;margin-bottom:15px;font-weight:bold;color:#a2a2a2;font-size:18px;height:200px;display: flex;flex-direction: column;justify-content: center;align-items: center;border:1px solid #e4e4e4;">
      <div>
        Your account credit
      </div>
      <div>{{$store.state.profile?$store.state.profile.balance:0}} $</div>
    </div>
  </div>
  <div @click="copyUrl" style="cursor:pointer;font-weight:bold;display: flex;border:1px solid #6ab64a;height:50px;color:#fff;border-radius: 5px;">
      <div style="background-color:#6ab64a;padding:15px;display: flex;align-items: center">
        Share <template v-if="!$store.state.isMobile"> your url</template>
      </div>
      <div style="color:#6ab64a;padding:15px;display:flex;align-items:center;overflow: hidden;white-space: nowrap;">
        https://guesteco.com/?r <span v-if="$store.state.isMobile">...</span><span v-if="!$store.state.isMobile">={{$store.state.profile?$store.state.profile.referral_key:'no_key'}}</span>
      </div>
  </div>
  </div>
  <div style="display: flex;flex-direction: column;padding-top:50px;" v-if="!isMobile">
    <div style="font-weight: bold;font-size:20px;margin-bottom:15px;">Your referrals</div>
    <div style="display: flex;width:100%;">
      <div style="border-radius:5px;margin-right:15px;flex:1;border:1px solid #6ab64a;color:#6ab64a;font-weight:bold;font-size:18px;padding:30px;display:flex;align-items: center;justify-content: center;flex-direction: column;">
        <div>Total link</div>
        <div>Clicks</div>
        <div>{{$store.state.profile?$store.state.profile.referrals_count:0}}</div>
      </div>
      <div style="border-radius:5px;margin-right:15px;flex:1;border:1px solid #e4e4e4;color:#a2a2a2;font-weight:bold;font-size:18px;padding:30px;display:flex;align-items: center;justify-content: center;flex-direction: column;">
        <div>Total Confirmed</div>
        <div>Referrals</div>
        <div>{{$store.state.profile?$store.state.profile.referrals_count_confirmed:0}}</div>
      </div>
      <div style="border-radius:5px;flex:1;border:1px solid #e4e4e4;padding:30px;color:#a2a2a2;font-weight:bold;font-size:18px;display:flex;align-items: center;justify-content: center;flex-direction: column;">
        <div>Total Rewards</div>
        <div>Earned</div>
        <div>{{$store.state.profile?$store.state.profile.sum_balance:0}} $</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";
export default {
  mixins:[titleMixin],
  title(){
    return 'Referrals'
  },
  name: "Referrals",
  data(){
    return{
      copied:false,
      isMobile:false
    }
  },
  methods:{
    copyUrl(){
      this.copied = true;
      navigator.clipboard.writeText(`https://guesteco.com/?r=${this.$store.state.profile?this.$store.state.profile.referral_key:'no_key'}`);
      setTimeout(() => {
        this.copied = false;
      },3000)
    }
  },
  created(){
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
}
</script>

<style scoped lang="scss">

.copiedTelegram{
  position: fixed;
  left:calc(50% - 185px);
  top:5px;
  z-index: 10;
  width:370px;
}
</style>
